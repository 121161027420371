/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import ContractDesignStudioTemplate from '~/templates/contract-design-studio'

type Props = {}

const ContractDesignStudioPage: FC<Props> = () => {
  return <ContractDesignStudioTemplate />
}

export default ContractDesignStudioPage
